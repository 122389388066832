import { graphql } from "gatsby";
import get from "lodash/get";
import Seo from "../../../../src/components/seo";
import { Layout } from "../../../../src/components/mdxLayout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { IconBackground } from "../../../../src/components/IconBackground";
import * as React from 'react';
export default {
  graphql,
  get,
  Seo,
  Layout,
  GatsbyImage,
  getImage,
  Link,
  IconBackground,
  React
};