/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';

export const onRouteUpdate = ({ location }) => {
  // If the string starts with /sms/pricing
  if (location.pathname.startsWith('/sms/pricing')) {
    window.location.replace('https://trybesoftware.notion.site/SMS-Pricing-a1ca7190f03147e6b1721bfcb952b81a?pvs=25');
  }
};
