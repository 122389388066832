import React from 'react'
import { LogoProps } from '../interfaces/LogoProps'

export const Logo: React.FC<LogoProps> = ({variant, width = 130, icon = false}) => (
  <svg
    className="transition"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={icon ? "0 0 27.25 27.25" : "0 0 131.25 27.25"}
    width={width}
    style={{minHeight: 20}}
  >
    <g>
      <g>
        {!icon && (
          <path
            fill={variant === 'light' ? '#330033' : '#fff'}
            d="M45.45 8.37h-4.59a.54.54 0 01-.54-.53v-2a.54.54 0 01.54-.54h13.86a.54.54 0 01.53.54v2a.53.53 0 01-.53.53h-4.59a.54.54 0 00-.54.54v12.52a.54.54 0 01-.54.54h-2.53a.54.54 0 01-.53-.54V8.91a.54.54 0 00-.54-.54zM59.87 5.82a.54.54 0 01.54-.54H67c4.91 0 7.49 2.89 7.49 6.47a6 6 0 01-2.92 5.16.26.26 0 00-.08.37l3 4.26a.27.27 0 01-.22.43H71a.55.55 0 01-.45-.25l-2.23-3.38a.52.52 0 00-.52-.24 7.62 7.62 0 01-1.08.08H64a.54.54 0 00-.54.54v2.71a.54.54 0 01-.53.54h-2.52a.54.54 0 01-.54-.54zm7.26 9.27a3.38 3.38 0 100-6.74H64a.54.54 0 00-.54.53v5.68a.54.54 0 00.54.53zM83.48 14.29l-5.61-8.17a.54.54 0 01.44-.84h2.81a.54.54 0 01.45.24l2.54 3.73c.43.62.81 1.28 1 1.69a.26.26 0 00.46 0c.25-.42.65-1.09 1-1.7l2.53-3.71a.55.55 0 01.45-.25h2.81a.54.54 0 01.45.84l-5.54 8.14a.54.54 0 00-.1.3v6.87a.54.54 0 01-.53.54h-2.53a.54.54 0 01-.54-.54v-6.84a.53.53 0 00-.09-.3zM97.86 5.82a.54.54 0 01.53-.54h7.34c2.76 0 5.2 1.16 5.2 4.35a3.7 3.7 0 01-1.23 2.67.26.26 0 00.07.44 4.23 4.23 0 012.68 4.08c0 3.52-2.78 5.15-6.46 5.15h-7.6a.54.54 0 01-.53-.54zm7.62 5.85c1.26 0 1.88-.62 1.88-1.62s-.62-1.68-2-1.68H102a.54.54 0 00-.54.54v2.22a.54.54 0 00.54.54zm.33 7.21c1.73 0 2.88-.62 2.88-2.11s-1.08-2.19-2.67-2.19h-4a.54.54 0 00-.54.53v3.23a.54.54 0 00.54.54zM118.37 5.28h12a.54.54 0 01.54.54v2a.54.54 0 01-.54.53H122a.54.54 0 00-.54.54v2.32a.54.54 0 00.54.54h7.88a.54.54 0 01.54.54v2a.54.54 0 01-.54.53H122a.54.54 0 00-.54.54v3a.54.54 0 00.54.54h8.73a.54.54 0 01.54.53v2a.54.54 0 01-.54.54h-12.36a.54.54 0 01-.53-.54V5.82a.54.54 0 01.53-.54z"
          />
        )}
        <path 
          d="M23.71 27.06l-4.66-3.17a1.07 1.07 0 01-.47-.9l.16-8.91c0-1.46.11-3 .18-4a.53.53 0 00-.78-.51c-.84.42-2.21 1.09-3.61 1.63l-8.41 3.3a1.08 1.08 0 01-1-.11L.47 11.24a1.08 1.08 0 01.21-1.89L24.65 0a.53.53 0 01.73.5v25.68a1.06 1.06 0 01-1.67.88z"
          fill={variant === 'bright' ? '#fff' : '#f06'}
        />
      </g>
    </g>
  </svg>
)
