import { IconBackground } from "../components/IconBackground";
import Layout from "../components/mdxLayout";
import React from 'react'

export const Legal = ({title, children}) => (
  <Layout>
    <div className="bg-grape py-12 relative overflow-hidden">
      <div className="text-white text-opacity-10">
        <IconBackground/>
      </div>
      <h1 className="text-center text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
        {title}
      </h1>
    </div>
    <div className="bg-white py-12 shadow-overlay">
      <div className="max-w-5xl p-4 sm:p-6 lg:p-8 mx-auto prose">
        {children}
      </div>
    </div>
  </Layout>
)
