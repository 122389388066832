import React, { Fragment, useState } from 'react'
import Cookies from 'js-cookie'
import { Transition } from '@headlessui/react'
import { Modal } from './Modal'
import { Toggle } from './Toggle'

export const cookieOptions = {
  secure: true,
  // Expires 6 months from now
  expires: 180,
}

export const CookiePolicy = () => {
  const [showSettings, setShowSettings] = useState(false)
  const [visible, setVisible] = useState(() => {
    return ! Cookies.get('trybe-cookies-consent-complete-2')
  })

  const handleAcceptAll = () => {
    Cookies.set(`trybe-cookie-consent-2`, 'true', cookieOptions)
    Cookies.set(`trybe-cookies-consent-complete-2`, 'true', cookieOptions)

    hideConsentPopup()
  }

  const handlePreferencesSaved = (preference: any) => {
    console.log('handlePreferencesSaved', preference)
    Cookies.set(`trybe-cookie-consent-2`, preference ? 'true' : 'false', cookieOptions)
    Cookies.set(`trybe-cookies-consent-complete-2`, 'true', cookieOptions)

    setShowSettings(false)
    hideConsentPopup()
  }

  const hideConsentPopup = () => {
    // Record that the consent form has been completed.
    Cookies.set('trybe-cookies-consent-complete-2', 'true', cookieOptions)
    setVisible(false)
  }

  return (
    <>
      <li>
        <button
          className="text-purple-lighter hover:text-white"
          title="Manage cookies"
          onClick={() => setShowSettings(true)}
        >
          Manage cookies
        </button>
      </li>

      <Transition
        show={visible}
        as={Fragment}
        enter="ease-out duration-300 transition"
        enterFrom="transform opacity-0 translate-y-40"
        enterTo="transform opacity-100 translate-y-0"
        leave="transform ease-in duration-200"
        leaveFrom="transform opacity-100 translate-y-0"
        leaveTo="transform opacity-0 translate-y-40"
      >
        <div className="z-10 fixed bottom-0 left-0 bg-white border border-gray-200 p-4 rounded-lg shadow-lg m-6 max-w-[420px] text-sm transition-transform duration-500" id="cookie-policy">
          <div className="text-gray-600">
              We use cookies to provide necessary website functionality, improve your experience and analyse our traffic.
          </div>

          <div className="flex mt-3 items-center space-x-5">
            <button
              className="py-2 px-4 font-medium rounded-md text-white bg-grape"
              onClick={handleAcceptAll}
            >
              Accept All
            </button>

            <button
              className="font-medium text-grape hover:underline"
              onClick={() => setShowSettings(true)}
              type="button"
            >
              Options
            </button>
          </div>
        </div>
      </Transition>

      <SettingsModal
        open={showSettings}
        onClose={() => setShowSettings(false)}
        onSavePreferences={handlePreferencesSaved}
      />
    </>
  )
}

export const SettingsModal = ({
  open,
  onClose,
  onSavePreferences
}: any) => {
  const [checked, setChecked] = useState(() => {
    // We'll check it by default if the cookie isn't set.
    // If it _is_ set and it's set to false, make sure we don't check it.
    return Cookies.get('trybe-cookie-consent-2') !== 'false'
  })

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <div className="p-6">
        <h2 className="text-2xl font-medium mb-4">Cookie Settings</h2>
        <p className="text-sm text-gray-500">
        We use cookies to provide necessary website functionality, improve your experience and analyse our traffic.
        For more information, please see our <a href="/legal/privacy-policy" className="text-violet-400 hover:underline font-medium">privacy policy</a>.
        </p>
        <ul className="space-y-6 py-8">
          <li className="flex w-full space-x-6">
            <Toggle
              checked
              disabled
            />
            <div className="flex-1 font-medium">
              Strictly necessary cookies
            </div>
          </li>
          <li className="flex w-full space-x-6">
            <Toggle
              checked={checked}
              onChange={setChecked}
            />
            <div className="flex-1 font-medium">
              Analytics cookies
            </div>
          </li>
        </ul>
        <button
          type="button"
          className="w-full text-white bg-grape py-3 px-6 rounded-lg"
          onClick={() => onSavePreferences(checked)}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
