import React from 'react'

export const IconBackground = () => (
  <div className="absolute inset-y-0 h-full w-full" aria-hidden="true">
    <div className="relative h-full overflow-hidden">
      <svg
        width={400}
        height={400}
        viewBox="0 0 1200 1025"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-full transform translate-x-[25%] md:translate-x-full"
      >
        <g>
          <path d="M160.583 193.333H180.167V185.5C180.167 179.011 174.906 173.75 168.417 173.75C164.674 173.75 161.34 175.5 159.188 178.226M160.583 193.333H121.417M160.583 193.333V185.5C160.583 182.93 160.088 180.475 159.188 178.226M121.417 193.333H101.833V185.5C101.833 179.011 107.094 173.75 113.583 173.75C117.326 173.75 120.66 175.5 122.812 178.226M121.417 193.333V185.5C121.417 182.93 121.912 180.475 122.812 178.226M122.812 178.226C125.7 171.012 132.755 165.917 141 165.917C149.245 165.917 156.3 171.012 159.188 178.226M152.75 142.417C152.75 148.906 147.489 154.167 141 154.167C134.511 154.167 129.25 148.906 129.25 142.417C129.25 135.927 134.511 130.667 141 130.667C147.489 130.667 152.75 135.927 152.75 142.417ZM176.25 154.167C176.25 158.493 172.743 162 168.417 162C164.09 162 160.583 158.493 160.583 154.167C160.583 149.84 164.09 146.333 168.417 146.333C172.743 146.333 176.25 149.84 176.25 154.167ZM121.417 154.167C121.417 158.493 117.91 162 113.583 162C109.257 162 105.75 158.493 105.75 154.167C105.75 149.84 109.257 146.333 113.583 146.333C117.91 146.333 121.417 149.84 121.417 154.167Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M840.583 173.333H860.167V165.5C860.167 159.011 854.906 153.75 848.417 153.75C844.674 153.75 841.34 155.5 839.188 158.226M840.583 173.333H801.417M840.583 173.333V165.5C840.583 162.93 840.088 160.475 839.188 158.226M801.417 173.333H781.833V165.5C781.833 159.011 787.094 153.75 793.583 153.75C797.326 153.75 800.66 155.5 802.812 158.226M801.417 173.333V165.5C801.417 162.93 801.912 160.475 802.812 158.226M802.812 158.226C805.7 151.012 812.755 145.917 821 145.917C829.245 145.917 836.3 151.012 839.188 158.226M832.75 122.417C832.75 128.906 827.489 134.167 821 134.167C814.511 134.167 809.25 128.906 809.25 122.417C809.25 115.927 814.511 110.667 821 110.667C827.489 110.667 832.75 115.927 832.75 122.417ZM856.25 134.167C856.25 138.493 852.743 142 848.417 142C844.09 142 840.583 138.493 840.583 134.167C840.583 129.84 844.09 126.333 848.417 126.333C852.743 126.333 856.25 129.84 856.25 134.167ZM801.417 134.167C801.417 138.493 797.91 142 793.583 142C789.257 142 785.75 138.493 785.75 134.167C785.75 129.84 789.257 126.333 793.583 126.333C797.91 126.333 801.417 129.84 801.417 134.167Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M85.75 427.333L116.655 447.937C119.286 449.691 122.714 449.691 125.345 447.937L156.25 427.333M93.5833 470.417H148.417C152.743 470.417 156.25 466.91 156.25 462.583V423.417C156.25 419.09 152.743 415.583 148.417 415.583H93.5833C89.2571 415.583 85.75 419.09 85.75 423.417V462.583C85.75 466.91 89.2571 470.417 93.5833 470.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M697.75 397.333L728.655 417.937C731.286 419.691 734.714 419.691 737.345 417.937L768.25 397.333M705.583 440.417H760.417C764.743 440.417 768.25 436.91 768.25 432.583V393.417C768.25 389.09 764.743 385.583 760.417 385.583H705.583C701.257 385.583 697.75 389.09 697.75 393.417V432.583C697.75 436.91 701.257 440.417 705.583 440.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M97.75 668.167H168.25M113.417 687.75H117.333M133 687.75H136.917M109.5 703.417H156.5C162.989 703.417 168.25 698.156 168.25 691.667V660.333C168.25 653.844 162.989 648.583 156.5 648.583H109.5C103.011 648.583 97.75 653.844 97.75 660.333V691.667C97.75 698.156 103.011 703.417 109.5 703.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M719.75 667.167H790.25M735.417 686.75H739.333M755 686.75H758.917M731.5 702.417H778.5C784.989 702.417 790.25 697.156 790.25 690.667V659.333C790.25 652.844 784.989 647.583 778.5 647.583H731.5C725.011 647.583 719.75 652.844 719.75 659.333V690.667C719.75 697.156 725.011 702.417 731.5 702.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M531.417 151.25V88.5833C531.417 84.2571 527.91 80.75 523.583 80.75H484.417C480.09 80.75 476.583 84.2571 476.583 88.5833V151.25M531.417 151.25L539.25 151.25M531.417 151.25H511.833M476.583 151.25L468.75 151.25M476.583 151.25H496.167M492.25 96.4166H496.167M492.25 112.083H496.167M511.833 96.4166H515.75M511.833 112.083H515.75M496.167 151.25V131.667C496.167 129.503 497.92 127.75 500.083 127.75H507.917C510.08 127.75 511.833 129.503 511.833 131.667V151.25M496.167 151.25H511.833" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M1101.42 111.25V48.5833C1101.42 44.2571 1097.91 40.75 1093.58 40.75H1054.42C1050.09 40.75 1046.58 44.2571 1046.58 48.5833V111.25M1101.42 111.25L1109.25 111.25M1101.42 111.25H1081.83M1046.58 111.25L1038.75 111.25M1046.58 111.25H1066.17M1062.25 56.4166H1066.17M1062.25 72.0832H1066.17M1081.83 56.4166H1085.75M1081.83 72.0832H1085.75M1066.17 111.25V91.6666C1066.17 89.5035 1067.92 87.7499 1070.08 87.7499H1077.92C1080.08 87.7499 1081.83 89.5035 1081.83 91.6666V111.25M1066.17 111.25H1081.83" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M411.965 366.083H419.583C423.91 366.083 427.417 369.59 427.417 373.917V377.833C427.417 382.16 430.924 385.667 435.25 385.667C439.576 385.667 443.083 389.174 443.083 393.5V405.035M431.333 338.414V344.542C431.333 349.949 435.717 354.333 441.125 354.333H443.083C447.41 354.333 450.917 357.84 450.917 362.167C450.917 366.493 454.424 370 458.75 370C463.076 370 466.583 366.493 466.583 362.167C466.583 357.84 470.09 354.333 474.417 354.333L478.586 354.333M458.75 403.244V393.5C458.75 389.174 462.257 385.667 466.583 385.667H478.586M482.25 370C482.25 389.468 466.468 405.25 447 405.25C427.532 405.25 411.75 389.468 411.75 370C411.75 350.532 427.532 334.75 447 334.75C466.468 334.75 482.25 350.532 482.25 370Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M981.965 326.083H989.583C993.91 326.083 997.417 329.59 997.417 333.917V337.833C997.417 342.16 1000.92 345.667 1005.25 345.667C1009.58 345.667 1013.08 349.174 1013.08 353.5V365.035M1001.33 298.414V304.542C1001.33 309.949 1005.72 314.333 1011.13 314.333H1013.08C1017.41 314.333 1020.92 317.84 1020.92 322.167C1020.92 326.493 1024.42 330 1028.75 330C1033.08 330 1036.58 326.493 1036.58 322.167C1036.58 317.84 1040.09 314.333 1044.42 314.333L1048.59 314.333M1028.75 363.244V353.5C1028.75 349.174 1032.26 345.667 1036.58 345.667H1048.59M1052.25 330C1052.25 349.468 1036.47 365.25 1017 365.25C997.532 365.25 981.75 349.468 981.75 330C981.75 310.532 997.532 294.75 1017 294.75C1036.47 294.75 1052.25 310.532 1052.25 330Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M466.018 628.066C465.041 627.089 463.459 627.089 462.482 628.066C461.506 629.042 461.506 630.625 462.482 631.601L466.018 628.066ZM472.083 637.667L470.316 639.434C471.292 640.411 472.875 640.411 473.851 639.434L472.083 637.667ZM489.518 623.768C490.494 622.791 490.494 621.209 489.518 620.232C488.541 619.256 486.959 619.256 485.982 620.232L489.518 623.768ZM500.917 602.417V649.417H505.917V602.417H500.917ZM495.583 654.75H456.417V659.75H495.583V654.75ZM451.083 649.417V602.417H446.083V649.417H451.083ZM456.417 597.083H464.25V592.083H456.417V597.083ZM487.75 597.083H495.583V592.083H487.75V597.083ZM456.417 654.75C453.471 654.75 451.083 652.362 451.083 649.417H446.083C446.083 655.124 450.71 659.75 456.417 659.75V654.75ZM500.917 649.417C500.917 652.362 498.529 654.75 495.583 654.75V659.75C501.29 659.75 505.917 655.124 505.917 649.417H500.917ZM505.917 602.417C505.917 596.71 501.29 592.083 495.583 592.083V597.083C498.529 597.083 500.917 599.471 500.917 602.417H505.917ZM451.083 602.417C451.083 599.471 453.471 597.083 456.417 597.083V592.083C450.71 592.083 446.083 596.71 446.083 602.417H451.083ZM462.482 631.601L470.316 639.434L473.851 635.899L466.018 628.066L462.482 631.601ZM473.851 639.434L489.518 623.768L485.982 620.232L470.316 635.899L473.851 639.434ZM472.083 589.25H479.917V584.25H472.083V589.25ZM479.917 599.917H472.083V604.917H479.917V599.917ZM472.083 599.917C469.138 599.917 466.75 597.529 466.75 594.583H461.75C461.75 600.29 466.376 604.917 472.083 604.917V599.917ZM485.25 594.583C485.25 597.529 482.862 599.917 479.917 599.917V604.917C485.624 604.917 490.25 600.29 490.25 594.583H485.25ZM479.917 589.25C482.862 589.25 485.25 591.638 485.25 594.583H490.25C490.25 588.876 485.624 584.25 479.917 584.25V589.25ZM472.083 584.25C466.376 584.25 461.75 588.876 461.75 594.583H466.75C466.75 591.638 469.138 589.25 472.083 589.25V584.25Z" fill="currentColor" />
          <path d="M996.018 588.066C995.042 587.089 993.459 587.089 992.482 588.066C991.506 589.042 991.506 590.625 992.482 591.601L996.018 588.066ZM1002.08 597.667L1000.32 599.434C1001.29 600.411 1002.87 600.411 1003.85 599.434L1002.08 597.667ZM1019.52 583.768C1020.49 582.791 1020.49 581.209 1019.52 580.232C1018.54 579.256 1016.96 579.256 1015.98 580.232L1019.52 583.768ZM1030.92 562.417V609.417H1035.92V562.417H1030.92ZM1025.58 614.75H986.417V619.75H1025.58V614.75ZM981.083 609.417V562.417H976.083V609.417H981.083ZM986.417 557.083H994.25V552.083H986.417V557.083ZM1017.75 557.083H1025.58V552.083H1017.75V557.083ZM986.417 614.75C983.471 614.75 981.083 612.362 981.083 609.417H976.083C976.083 615.124 980.71 619.75 986.417 619.75V614.75ZM1030.92 609.417C1030.92 612.362 1028.53 614.75 1025.58 614.75V619.75C1031.29 619.75 1035.92 615.124 1035.92 609.417H1030.92ZM1035.92 562.417C1035.92 556.71 1031.29 552.083 1025.58 552.083V557.083C1028.53 557.083 1030.92 559.471 1030.92 562.417H1035.92ZM981.083 562.417C981.083 559.471 983.471 557.083 986.417 557.083V552.083C980.71 552.083 976.083 556.71 976.083 562.417H981.083ZM992.482 591.601L1000.32 599.434L1003.85 595.899L996.018 588.066L992.482 591.601ZM1003.85 599.434L1019.52 583.768L1015.98 580.232L1000.32 595.899L1003.85 599.434ZM1002.08 549.25H1009.92V544.25H1002.08V549.25ZM1009.92 559.917H1002.08V564.917H1009.92V559.917ZM1002.08 559.917C999.138 559.917 996.75 557.529 996.75 554.583H991.75C991.75 560.29 996.376 564.917 1002.08 564.917V559.917ZM1015.25 554.583C1015.25 557.529 1012.86 559.917 1009.92 559.917V564.917C1015.62 564.917 1020.25 560.29 1020.25 554.583H1015.25ZM1009.92 549.25C1012.86 549.25 1015.25 551.638 1015.25 554.583H1020.25C1020.25 548.876 1015.62 544.25 1009.92 544.25V549.25ZM1002.08 544.25C996.376 544.25 991.75 548.876 991.75 554.583H996.75C996.75 551.638 999.138 549.25 1002.08 549.25V544.25Z" fill="currentColor" />
          <path d="M212.75 909C212.75 927.087 198.087 941.75 180 941.75V946.75C200.849 946.75 217.75 929.849 217.75 909H212.75ZM180 941.75C161.913 941.75 147.25 927.087 147.25 909H142.25C142.25 929.849 159.151 946.75 180 946.75V941.75ZM147.25 909C147.25 890.913 161.913 876.25 180 876.25V871.25C159.151 871.25 142.25 888.151 142.25 909H147.25ZM180 876.25C198.087 876.25 212.75 890.913 212.75 909H217.75C217.75 888.151 200.849 871.25 180 871.25V876.25ZM193.167 909C193.167 916.272 187.272 922.167 180 922.167V927.167C190.033 927.167 198.167 919.033 198.167 909H193.167ZM180 922.167C172.728 922.167 166.833 916.272 166.833 909H161.833C161.833 919.033 169.967 927.167 180 927.167V922.167ZM166.833 909C166.833 901.728 172.728 895.833 180 895.833V890.833C169.967 890.833 161.833 898.967 161.833 909H166.833ZM180 895.833C187.272 895.833 193.167 901.728 193.167 909H198.167C198.167 898.967 190.033 890.833 180 890.833V895.833ZM203.158 882.307L189.31 896.154L192.846 899.69L206.693 885.842L203.158 882.307ZM189.31 921.846L203.158 935.693L206.693 932.158L192.846 918.31L189.31 921.846ZM170.69 896.154L156.842 882.307L153.307 885.842L167.154 899.69L170.69 896.154ZM167.154 918.31L153.307 932.158L156.842 935.693L170.69 921.846L167.154 918.31Z" fill="currentColor" />
          <path d="M762.75 929C762.75 947.087 748.087 961.75 730 961.75V966.75C750.849 966.75 767.75 949.849 767.75 929H762.75ZM730 961.75C711.913 961.75 697.25 947.087 697.25 929H692.25C692.25 949.849 709.151 966.75 730 966.75V961.75ZM697.25 929C697.25 910.913 711.913 896.25 730 896.25V891.25C709.151 891.25 692.25 908.151 692.25 929H697.25ZM730 896.25C748.087 896.25 762.75 910.913 762.75 929H767.75C767.75 908.151 750.849 891.25 730 891.25V896.25ZM743.167 929C743.167 936.272 737.272 942.167 730 942.167V947.167C740.033 947.167 748.167 939.033 748.167 929H743.167ZM730 942.167C722.728 942.167 716.833 936.272 716.833 929H711.833C711.833 939.033 719.967 947.167 730 947.167V942.167ZM716.833 929C716.833 921.728 722.728 915.833 730 915.833V910.833C719.967 910.833 711.833 918.967 711.833 929H716.833ZM730 915.833C737.272 915.833 743.167 921.728 743.167 929H748.167C748.167 918.967 740.033 910.833 730 910.833V915.833ZM753.158 902.307L739.31 916.154L742.846 919.69L756.693 905.842L753.158 902.307ZM739.31 941.846L753.158 955.693L756.693 952.158L742.846 938.31L739.31 941.846ZM720.69 916.154L706.842 902.307L703.307 905.842L717.154 919.69L720.69 916.154ZM717.154 938.31L703.307 952.158L706.842 955.693L720.69 941.846L717.154 938.31Z" fill="currentColor" />
          <path d="M467.167 850.5H447.583C443.257 850.5 439.75 854.007 439.75 858.333V893.583C439.75 897.91 443.257 901.417 447.583 901.417H502.417C506.743 901.417 510.25 897.91 510.25 893.583V858.333C510.25 854.007 506.743 850.5 502.417 850.5H482.833M467.167 850.5V846.583C467.167 842.257 470.674 838.75 475 838.75C479.326 838.75 482.833 842.257 482.833 846.583V850.5M467.167 850.5C467.167 854.826 470.674 858.333 475 858.333C479.326 858.333 482.833 854.826 482.833 850.5M463.25 881.833C467.576 881.833 471.083 878.326 471.083 874C471.083 869.674 467.576 866.167 463.25 866.167C458.924 866.167 455.417 869.674 455.417 874C455.417 878.326 458.924 881.833 463.25 881.833ZM463.25 881.833C468.366 881.833 472.718 885.103 474.331 889.667M463.25 881.833C458.134 881.833 453.781 885.103 452.168 889.667M486.75 870.083H498.5M486.75 885.75H494.583" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M1027.17 830.5H1007.58C1003.26 830.5 999.75 834.007 999.75 838.333V873.583C999.75 877.91 1003.26 881.417 1007.58 881.417H1062.42C1066.74 881.417 1070.25 877.91 1070.25 873.583V838.333C1070.25 834.007 1066.74 830.5 1062.42 830.5H1042.83M1027.17 830.5V826.583C1027.17 822.257 1030.67 818.75 1035 818.75C1039.33 818.75 1042.83 822.257 1042.83 826.583V830.5M1027.17 830.5C1027.17 834.826 1030.67 838.333 1035 838.333C1039.33 838.333 1042.83 834.826 1042.83 830.5M1023.25 861.833C1027.58 861.833 1031.08 858.326 1031.08 854C1031.08 849.674 1027.58 846.167 1023.25 846.167C1018.92 846.167 1015.42 849.674 1015.42 854C1015.42 858.326 1018.92 861.833 1023.25 861.833ZM1023.25 861.833C1028.37 861.833 1032.72 865.103 1034.33 869.667M1023.25 861.833C1018.13 861.833 1013.78 865.103 1012.17 869.667M1046.75 850.083H1058.5M1046.75 865.75H1054.58" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      </svg>
      <svg
        width={400}
        height={400}
        viewBox="0 0 1200 1025"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute hidden md:block right-full transform translate-y-[350px] translate-x-full"
      >
        <g>
          <path d="M160.583 193.333H180.167V185.5C180.167 179.011 174.906 173.75 168.417 173.75C164.674 173.75 161.34 175.5 159.188 178.226M160.583 193.333H121.417M160.583 193.333V185.5C160.583 182.93 160.088 180.475 159.188 178.226M121.417 193.333H101.833V185.5C101.833 179.011 107.094 173.75 113.583 173.75C117.326 173.75 120.66 175.5 122.812 178.226M121.417 193.333V185.5C121.417 182.93 121.912 180.475 122.812 178.226M122.812 178.226C125.7 171.012 132.755 165.917 141 165.917C149.245 165.917 156.3 171.012 159.188 178.226M152.75 142.417C152.75 148.906 147.489 154.167 141 154.167C134.511 154.167 129.25 148.906 129.25 142.417C129.25 135.927 134.511 130.667 141 130.667C147.489 130.667 152.75 135.927 152.75 142.417ZM176.25 154.167C176.25 158.493 172.743 162 168.417 162C164.09 162 160.583 158.493 160.583 154.167C160.583 149.84 164.09 146.333 168.417 146.333C172.743 146.333 176.25 149.84 176.25 154.167ZM121.417 154.167C121.417 158.493 117.91 162 113.583 162C109.257 162 105.75 158.493 105.75 154.167C105.75 149.84 109.257 146.333 113.583 146.333C117.91 146.333 121.417 149.84 121.417 154.167Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M840.583 173.333H860.167V165.5C860.167 159.011 854.906 153.75 848.417 153.75C844.674 153.75 841.34 155.5 839.188 158.226M840.583 173.333H801.417M840.583 173.333V165.5C840.583 162.93 840.088 160.475 839.188 158.226M801.417 173.333H781.833V165.5C781.833 159.011 787.094 153.75 793.583 153.75C797.326 153.75 800.66 155.5 802.812 158.226M801.417 173.333V165.5C801.417 162.93 801.912 160.475 802.812 158.226M802.812 158.226C805.7 151.012 812.755 145.917 821 145.917C829.245 145.917 836.3 151.012 839.188 158.226M832.75 122.417C832.75 128.906 827.489 134.167 821 134.167C814.511 134.167 809.25 128.906 809.25 122.417C809.25 115.927 814.511 110.667 821 110.667C827.489 110.667 832.75 115.927 832.75 122.417ZM856.25 134.167C856.25 138.493 852.743 142 848.417 142C844.09 142 840.583 138.493 840.583 134.167C840.583 129.84 844.09 126.333 848.417 126.333C852.743 126.333 856.25 129.84 856.25 134.167ZM801.417 134.167C801.417 138.493 797.91 142 793.583 142C789.257 142 785.75 138.493 785.75 134.167C785.75 129.84 789.257 126.333 793.583 126.333C797.91 126.333 801.417 129.84 801.417 134.167Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M85.75 427.333L116.655 447.937C119.286 449.691 122.714 449.691 125.345 447.937L156.25 427.333M93.5833 470.417H148.417C152.743 470.417 156.25 466.91 156.25 462.583V423.417C156.25 419.09 152.743 415.583 148.417 415.583H93.5833C89.2571 415.583 85.75 419.09 85.75 423.417V462.583C85.75 466.91 89.2571 470.417 93.5833 470.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M697.75 397.333L728.655 417.937C731.286 419.691 734.714 419.691 737.345 417.937L768.25 397.333M705.583 440.417H760.417C764.743 440.417 768.25 436.91 768.25 432.583V393.417C768.25 389.09 764.743 385.583 760.417 385.583H705.583C701.257 385.583 697.75 389.09 697.75 393.417V432.583C697.75 436.91 701.257 440.417 705.583 440.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M97.75 668.167H168.25M113.417 687.75H117.333M133 687.75H136.917M109.5 703.417H156.5C162.989 703.417 168.25 698.156 168.25 691.667V660.333C168.25 653.844 162.989 648.583 156.5 648.583H109.5C103.011 648.583 97.75 653.844 97.75 660.333V691.667C97.75 698.156 103.011 703.417 109.5 703.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M719.75 667.167H790.25M735.417 686.75H739.333M755 686.75H758.917M731.5 702.417H778.5C784.989 702.417 790.25 697.156 790.25 690.667V659.333C790.25 652.844 784.989 647.583 778.5 647.583H731.5C725.011 647.583 719.75 652.844 719.75 659.333V690.667C719.75 697.156 725.011 702.417 731.5 702.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M531.417 151.25V88.5833C531.417 84.2571 527.91 80.75 523.583 80.75H484.417C480.09 80.75 476.583 84.2571 476.583 88.5833V151.25M531.417 151.25L539.25 151.25M531.417 151.25H511.833M476.583 151.25L468.75 151.25M476.583 151.25H496.167M492.25 96.4166H496.167M492.25 112.083H496.167M511.833 96.4166H515.75M511.833 112.083H515.75M496.167 151.25V131.667C496.167 129.503 497.92 127.75 500.083 127.75H507.917C510.08 127.75 511.833 129.503 511.833 131.667V151.25M496.167 151.25H511.833" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M1101.42 111.25V48.5833C1101.42 44.2571 1097.91 40.75 1093.58 40.75H1054.42C1050.09 40.75 1046.58 44.2571 1046.58 48.5833V111.25M1101.42 111.25L1109.25 111.25M1101.42 111.25H1081.83M1046.58 111.25L1038.75 111.25M1046.58 111.25H1066.17M1062.25 56.4166H1066.17M1062.25 72.0832H1066.17M1081.83 56.4166H1085.75M1081.83 72.0832H1085.75M1066.17 111.25V91.6666C1066.17 89.5035 1067.92 87.7499 1070.08 87.7499H1077.92C1080.08 87.7499 1081.83 89.5035 1081.83 91.6666V111.25M1066.17 111.25H1081.83" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M411.965 366.083H419.583C423.91 366.083 427.417 369.59 427.417 373.917V377.833C427.417 382.16 430.924 385.667 435.25 385.667C439.576 385.667 443.083 389.174 443.083 393.5V405.035M431.333 338.414V344.542C431.333 349.949 435.717 354.333 441.125 354.333H443.083C447.41 354.333 450.917 357.84 450.917 362.167C450.917 366.493 454.424 370 458.75 370C463.076 370 466.583 366.493 466.583 362.167C466.583 357.84 470.09 354.333 474.417 354.333L478.586 354.333M458.75 403.244V393.5C458.75 389.174 462.257 385.667 466.583 385.667H478.586M482.25 370C482.25 389.468 466.468 405.25 447 405.25C427.532 405.25 411.75 389.468 411.75 370C411.75 350.532 427.532 334.75 447 334.75C466.468 334.75 482.25 350.532 482.25 370Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M981.965 326.083H989.583C993.91 326.083 997.417 329.59 997.417 333.917V337.833C997.417 342.16 1000.92 345.667 1005.25 345.667C1009.58 345.667 1013.08 349.174 1013.08 353.5V365.035M1001.33 298.414V304.542C1001.33 309.949 1005.72 314.333 1011.13 314.333H1013.08C1017.41 314.333 1020.92 317.84 1020.92 322.167C1020.92 326.493 1024.42 330 1028.75 330C1033.08 330 1036.58 326.493 1036.58 322.167C1036.58 317.84 1040.09 314.333 1044.42 314.333L1048.59 314.333M1028.75 363.244V353.5C1028.75 349.174 1032.26 345.667 1036.58 345.667H1048.59M1052.25 330C1052.25 349.468 1036.47 365.25 1017 365.25C997.532 365.25 981.75 349.468 981.75 330C981.75 310.532 997.532 294.75 1017 294.75C1036.47 294.75 1052.25 310.532 1052.25 330Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M466.018 628.066C465.041 627.089 463.459 627.089 462.482 628.066C461.506 629.042 461.506 630.625 462.482 631.601L466.018 628.066ZM472.083 637.667L470.316 639.434C471.292 640.411 472.875 640.411 473.851 639.434L472.083 637.667ZM489.518 623.768C490.494 622.791 490.494 621.209 489.518 620.232C488.541 619.256 486.959 619.256 485.982 620.232L489.518 623.768ZM500.917 602.417V649.417H505.917V602.417H500.917ZM495.583 654.75H456.417V659.75H495.583V654.75ZM451.083 649.417V602.417H446.083V649.417H451.083ZM456.417 597.083H464.25V592.083H456.417V597.083ZM487.75 597.083H495.583V592.083H487.75V597.083ZM456.417 654.75C453.471 654.75 451.083 652.362 451.083 649.417H446.083C446.083 655.124 450.71 659.75 456.417 659.75V654.75ZM500.917 649.417C500.917 652.362 498.529 654.75 495.583 654.75V659.75C501.29 659.75 505.917 655.124 505.917 649.417H500.917ZM505.917 602.417C505.917 596.71 501.29 592.083 495.583 592.083V597.083C498.529 597.083 500.917 599.471 500.917 602.417H505.917ZM451.083 602.417C451.083 599.471 453.471 597.083 456.417 597.083V592.083C450.71 592.083 446.083 596.71 446.083 602.417H451.083ZM462.482 631.601L470.316 639.434L473.851 635.899L466.018 628.066L462.482 631.601ZM473.851 639.434L489.518 623.768L485.982 620.232L470.316 635.899L473.851 639.434ZM472.083 589.25H479.917V584.25H472.083V589.25ZM479.917 599.917H472.083V604.917H479.917V599.917ZM472.083 599.917C469.138 599.917 466.75 597.529 466.75 594.583H461.75C461.75 600.29 466.376 604.917 472.083 604.917V599.917ZM485.25 594.583C485.25 597.529 482.862 599.917 479.917 599.917V604.917C485.624 604.917 490.25 600.29 490.25 594.583H485.25ZM479.917 589.25C482.862 589.25 485.25 591.638 485.25 594.583H490.25C490.25 588.876 485.624 584.25 479.917 584.25V589.25ZM472.083 584.25C466.376 584.25 461.75 588.876 461.75 594.583H466.75C466.75 591.638 469.138 589.25 472.083 589.25V584.25Z" fill="currentColor" />
          <path d="M996.018 588.066C995.042 587.089 993.459 587.089 992.482 588.066C991.506 589.042 991.506 590.625 992.482 591.601L996.018 588.066ZM1002.08 597.667L1000.32 599.434C1001.29 600.411 1002.87 600.411 1003.85 599.434L1002.08 597.667ZM1019.52 583.768C1020.49 582.791 1020.49 581.209 1019.52 580.232C1018.54 579.256 1016.96 579.256 1015.98 580.232L1019.52 583.768ZM1030.92 562.417V609.417H1035.92V562.417H1030.92ZM1025.58 614.75H986.417V619.75H1025.58V614.75ZM981.083 609.417V562.417H976.083V609.417H981.083ZM986.417 557.083H994.25V552.083H986.417V557.083ZM1017.75 557.083H1025.58V552.083H1017.75V557.083ZM986.417 614.75C983.471 614.75 981.083 612.362 981.083 609.417H976.083C976.083 615.124 980.71 619.75 986.417 619.75V614.75ZM1030.92 609.417C1030.92 612.362 1028.53 614.75 1025.58 614.75V619.75C1031.29 619.75 1035.92 615.124 1035.92 609.417H1030.92ZM1035.92 562.417C1035.92 556.71 1031.29 552.083 1025.58 552.083V557.083C1028.53 557.083 1030.92 559.471 1030.92 562.417H1035.92ZM981.083 562.417C981.083 559.471 983.471 557.083 986.417 557.083V552.083C980.71 552.083 976.083 556.71 976.083 562.417H981.083ZM992.482 591.601L1000.32 599.434L1003.85 595.899L996.018 588.066L992.482 591.601ZM1003.85 599.434L1019.52 583.768L1015.98 580.232L1000.32 595.899L1003.85 599.434ZM1002.08 549.25H1009.92V544.25H1002.08V549.25ZM1009.92 559.917H1002.08V564.917H1009.92V559.917ZM1002.08 559.917C999.138 559.917 996.75 557.529 996.75 554.583H991.75C991.75 560.29 996.376 564.917 1002.08 564.917V559.917ZM1015.25 554.583C1015.25 557.529 1012.86 559.917 1009.92 559.917V564.917C1015.62 564.917 1020.25 560.29 1020.25 554.583H1015.25ZM1009.92 549.25C1012.86 549.25 1015.25 551.638 1015.25 554.583H1020.25C1020.25 548.876 1015.62 544.25 1009.92 544.25V549.25ZM1002.08 544.25C996.376 544.25 991.75 548.876 991.75 554.583H996.75C996.75 551.638 999.138 549.25 1002.08 549.25V544.25Z" fill="currentColor" />
          <path d="M212.75 909C212.75 927.087 198.087 941.75 180 941.75V946.75C200.849 946.75 217.75 929.849 217.75 909H212.75ZM180 941.75C161.913 941.75 147.25 927.087 147.25 909H142.25C142.25 929.849 159.151 946.75 180 946.75V941.75ZM147.25 909C147.25 890.913 161.913 876.25 180 876.25V871.25C159.151 871.25 142.25 888.151 142.25 909H147.25ZM180 876.25C198.087 876.25 212.75 890.913 212.75 909H217.75C217.75 888.151 200.849 871.25 180 871.25V876.25ZM193.167 909C193.167 916.272 187.272 922.167 180 922.167V927.167C190.033 927.167 198.167 919.033 198.167 909H193.167ZM180 922.167C172.728 922.167 166.833 916.272 166.833 909H161.833C161.833 919.033 169.967 927.167 180 927.167V922.167ZM166.833 909C166.833 901.728 172.728 895.833 180 895.833V890.833C169.967 890.833 161.833 898.967 161.833 909H166.833ZM180 895.833C187.272 895.833 193.167 901.728 193.167 909H198.167C198.167 898.967 190.033 890.833 180 890.833V895.833ZM203.158 882.307L189.31 896.154L192.846 899.69L206.693 885.842L203.158 882.307ZM189.31 921.846L203.158 935.693L206.693 932.158L192.846 918.31L189.31 921.846ZM170.69 896.154L156.842 882.307L153.307 885.842L167.154 899.69L170.69 896.154ZM167.154 918.31L153.307 932.158L156.842 935.693L170.69 921.846L167.154 918.31Z" fill="currentColor" />
          <path d="M762.75 929C762.75 947.087 748.087 961.75 730 961.75V966.75C750.849 966.75 767.75 949.849 767.75 929H762.75ZM730 961.75C711.913 961.75 697.25 947.087 697.25 929H692.25C692.25 949.849 709.151 966.75 730 966.75V961.75ZM697.25 929C697.25 910.913 711.913 896.25 730 896.25V891.25C709.151 891.25 692.25 908.151 692.25 929H697.25ZM730 896.25C748.087 896.25 762.75 910.913 762.75 929H767.75C767.75 908.151 750.849 891.25 730 891.25V896.25ZM743.167 929C743.167 936.272 737.272 942.167 730 942.167V947.167C740.033 947.167 748.167 939.033 748.167 929H743.167ZM730 942.167C722.728 942.167 716.833 936.272 716.833 929H711.833C711.833 939.033 719.967 947.167 730 947.167V942.167ZM716.833 929C716.833 921.728 722.728 915.833 730 915.833V910.833C719.967 910.833 711.833 918.967 711.833 929H716.833ZM730 915.833C737.272 915.833 743.167 921.728 743.167 929H748.167C748.167 918.967 740.033 910.833 730 910.833V915.833ZM753.158 902.307L739.31 916.154L742.846 919.69L756.693 905.842L753.158 902.307ZM739.31 941.846L753.158 955.693L756.693 952.158L742.846 938.31L739.31 941.846ZM720.69 916.154L706.842 902.307L703.307 905.842L717.154 919.69L720.69 916.154ZM717.154 938.31L703.307 952.158L706.842 955.693L720.69 941.846L717.154 938.31Z" fill="currentColor" />
          <path d="M467.167 850.5H447.583C443.257 850.5 439.75 854.007 439.75 858.333V893.583C439.75 897.91 443.257 901.417 447.583 901.417H502.417C506.743 901.417 510.25 897.91 510.25 893.583V858.333C510.25 854.007 506.743 850.5 502.417 850.5H482.833M467.167 850.5V846.583C467.167 842.257 470.674 838.75 475 838.75C479.326 838.75 482.833 842.257 482.833 846.583V850.5M467.167 850.5C467.167 854.826 470.674 858.333 475 858.333C479.326 858.333 482.833 854.826 482.833 850.5M463.25 881.833C467.576 881.833 471.083 878.326 471.083 874C471.083 869.674 467.576 866.167 463.25 866.167C458.924 866.167 455.417 869.674 455.417 874C455.417 878.326 458.924 881.833 463.25 881.833ZM463.25 881.833C468.366 881.833 472.718 885.103 474.331 889.667M463.25 881.833C458.134 881.833 453.781 885.103 452.168 889.667M486.75 870.083H498.5M486.75 885.75H494.583" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M1027.17 830.5H1007.58C1003.26 830.5 999.75 834.007 999.75 838.333V873.583C999.75 877.91 1003.26 881.417 1007.58 881.417H1062.42C1066.74 881.417 1070.25 877.91 1070.25 873.583V838.333C1070.25 834.007 1066.74 830.5 1062.42 830.5H1042.83M1027.17 830.5V826.583C1027.17 822.257 1030.67 818.75 1035 818.75C1039.33 818.75 1042.83 822.257 1042.83 826.583V830.5M1027.17 830.5C1027.17 834.826 1030.67 838.333 1035 838.333C1039.33 838.333 1042.83 834.826 1042.83 830.5M1023.25 861.833C1027.58 861.833 1031.08 858.326 1031.08 854C1031.08 849.674 1027.58 846.167 1023.25 846.167C1018.92 846.167 1015.42 849.674 1015.42 854C1015.42 858.326 1018.92 861.833 1023.25 861.833ZM1023.25 861.833C1028.37 861.833 1032.72 865.103 1034.33 869.667M1023.25 861.833C1018.13 861.833 1013.78 865.103 1012.17 869.667M1046.75 850.083H1058.5M1046.75 865.75H1054.58" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      </svg>

      <svg
        width={400}
        height={400}
        viewBox="0 0 1200 1025"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute left-full transform -translate-x-[25%] md:-translate-x-full"
      >
        <g>
          <path d="M160.583 193.333H180.167V185.5C180.167 179.011 174.906 173.75 168.417 173.75C164.674 173.75 161.34 175.5 159.188 178.226M160.583 193.333H121.417M160.583 193.333V185.5C160.583 182.93 160.088 180.475 159.188 178.226M121.417 193.333H101.833V185.5C101.833 179.011 107.094 173.75 113.583 173.75C117.326 173.75 120.66 175.5 122.812 178.226M121.417 193.333V185.5C121.417 182.93 121.912 180.475 122.812 178.226M122.812 178.226C125.7 171.012 132.755 165.917 141 165.917C149.245 165.917 156.3 171.012 159.188 178.226M152.75 142.417C152.75 148.906 147.489 154.167 141 154.167C134.511 154.167 129.25 148.906 129.25 142.417C129.25 135.927 134.511 130.667 141 130.667C147.489 130.667 152.75 135.927 152.75 142.417ZM176.25 154.167C176.25 158.493 172.743 162 168.417 162C164.09 162 160.583 158.493 160.583 154.167C160.583 149.84 164.09 146.333 168.417 146.333C172.743 146.333 176.25 149.84 176.25 154.167ZM121.417 154.167C121.417 158.493 117.91 162 113.583 162C109.257 162 105.75 158.493 105.75 154.167C105.75 149.84 109.257 146.333 113.583 146.333C117.91 146.333 121.417 149.84 121.417 154.167Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M840.583 173.333H860.167V165.5C860.167 159.011 854.906 153.75 848.417 153.75C844.674 153.75 841.34 155.5 839.188 158.226M840.583 173.333H801.417M840.583 173.333V165.5C840.583 162.93 840.088 160.475 839.188 158.226M801.417 173.333H781.833V165.5C781.833 159.011 787.094 153.75 793.583 153.75C797.326 153.75 800.66 155.5 802.812 158.226M801.417 173.333V165.5C801.417 162.93 801.912 160.475 802.812 158.226M802.812 158.226C805.7 151.012 812.755 145.917 821 145.917C829.245 145.917 836.3 151.012 839.188 158.226M832.75 122.417C832.75 128.906 827.489 134.167 821 134.167C814.511 134.167 809.25 128.906 809.25 122.417C809.25 115.927 814.511 110.667 821 110.667C827.489 110.667 832.75 115.927 832.75 122.417ZM856.25 134.167C856.25 138.493 852.743 142 848.417 142C844.09 142 840.583 138.493 840.583 134.167C840.583 129.84 844.09 126.333 848.417 126.333C852.743 126.333 856.25 129.84 856.25 134.167ZM801.417 134.167C801.417 138.493 797.91 142 793.583 142C789.257 142 785.75 138.493 785.75 134.167C785.75 129.84 789.257 126.333 793.583 126.333C797.91 126.333 801.417 129.84 801.417 134.167Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M85.75 427.333L116.655 447.937C119.286 449.691 122.714 449.691 125.345 447.937L156.25 427.333M93.5833 470.417H148.417C152.743 470.417 156.25 466.91 156.25 462.583V423.417C156.25 419.09 152.743 415.583 148.417 415.583H93.5833C89.2571 415.583 85.75 419.09 85.75 423.417V462.583C85.75 466.91 89.2571 470.417 93.5833 470.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M697.75 397.333L728.655 417.937C731.286 419.691 734.714 419.691 737.345 417.937L768.25 397.333M705.583 440.417H760.417C764.743 440.417 768.25 436.91 768.25 432.583V393.417C768.25 389.09 764.743 385.583 760.417 385.583H705.583C701.257 385.583 697.75 389.09 697.75 393.417V432.583C697.75 436.91 701.257 440.417 705.583 440.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M97.75 668.167H168.25M113.417 687.75H117.333M133 687.75H136.917M109.5 703.417H156.5C162.989 703.417 168.25 698.156 168.25 691.667V660.333C168.25 653.844 162.989 648.583 156.5 648.583H109.5C103.011 648.583 97.75 653.844 97.75 660.333V691.667C97.75 698.156 103.011 703.417 109.5 703.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M719.75 667.167H790.25M735.417 686.75H739.333M755 686.75H758.917M731.5 702.417H778.5C784.989 702.417 790.25 697.156 790.25 690.667V659.333C790.25 652.844 784.989 647.583 778.5 647.583H731.5C725.011 647.583 719.75 652.844 719.75 659.333V690.667C719.75 697.156 725.011 702.417 731.5 702.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M531.417 151.25V88.5833C531.417 84.2571 527.91 80.75 523.583 80.75H484.417C480.09 80.75 476.583 84.2571 476.583 88.5833V151.25M531.417 151.25L539.25 151.25M531.417 151.25H511.833M476.583 151.25L468.75 151.25M476.583 151.25H496.167M492.25 96.4166H496.167M492.25 112.083H496.167M511.833 96.4166H515.75M511.833 112.083H515.75M496.167 151.25V131.667C496.167 129.503 497.92 127.75 500.083 127.75H507.917C510.08 127.75 511.833 129.503 511.833 131.667V151.25M496.167 151.25H511.833" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M1101.42 111.25V48.5833C1101.42 44.2571 1097.91 40.75 1093.58 40.75H1054.42C1050.09 40.75 1046.58 44.2571 1046.58 48.5833V111.25M1101.42 111.25L1109.25 111.25M1101.42 111.25H1081.83M1046.58 111.25L1038.75 111.25M1046.58 111.25H1066.17M1062.25 56.4166H1066.17M1062.25 72.0832H1066.17M1081.83 56.4166H1085.75M1081.83 72.0832H1085.75M1066.17 111.25V91.6666C1066.17 89.5035 1067.92 87.7499 1070.08 87.7499H1077.92C1080.08 87.7499 1081.83 89.5035 1081.83 91.6666V111.25M1066.17 111.25H1081.83" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M411.965 366.083H419.583C423.91 366.083 427.417 369.59 427.417 373.917V377.833C427.417 382.16 430.924 385.667 435.25 385.667C439.576 385.667 443.083 389.174 443.083 393.5V405.035M431.333 338.414V344.542C431.333 349.949 435.717 354.333 441.125 354.333H443.083C447.41 354.333 450.917 357.84 450.917 362.167C450.917 366.493 454.424 370 458.75 370C463.076 370 466.583 366.493 466.583 362.167C466.583 357.84 470.09 354.333 474.417 354.333L478.586 354.333M458.75 403.244V393.5C458.75 389.174 462.257 385.667 466.583 385.667H478.586M482.25 370C482.25 389.468 466.468 405.25 447 405.25C427.532 405.25 411.75 389.468 411.75 370C411.75 350.532 427.532 334.75 447 334.75C466.468 334.75 482.25 350.532 482.25 370Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M981.965 326.083H989.583C993.91 326.083 997.417 329.59 997.417 333.917V337.833C997.417 342.16 1000.92 345.667 1005.25 345.667C1009.58 345.667 1013.08 349.174 1013.08 353.5V365.035M1001.33 298.414V304.542C1001.33 309.949 1005.72 314.333 1011.13 314.333H1013.08C1017.41 314.333 1020.92 317.84 1020.92 322.167C1020.92 326.493 1024.42 330 1028.75 330C1033.08 330 1036.58 326.493 1036.58 322.167C1036.58 317.84 1040.09 314.333 1044.42 314.333L1048.59 314.333M1028.75 363.244V353.5C1028.75 349.174 1032.26 345.667 1036.58 345.667H1048.59M1052.25 330C1052.25 349.468 1036.47 365.25 1017 365.25C997.532 365.25 981.75 349.468 981.75 330C981.75 310.532 997.532 294.75 1017 294.75C1036.47 294.75 1052.25 310.532 1052.25 330Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M466.018 628.066C465.041 627.089 463.459 627.089 462.482 628.066C461.506 629.042 461.506 630.625 462.482 631.601L466.018 628.066ZM472.083 637.667L470.316 639.434C471.292 640.411 472.875 640.411 473.851 639.434L472.083 637.667ZM489.518 623.768C490.494 622.791 490.494 621.209 489.518 620.232C488.541 619.256 486.959 619.256 485.982 620.232L489.518 623.768ZM500.917 602.417V649.417H505.917V602.417H500.917ZM495.583 654.75H456.417V659.75H495.583V654.75ZM451.083 649.417V602.417H446.083V649.417H451.083ZM456.417 597.083H464.25V592.083H456.417V597.083ZM487.75 597.083H495.583V592.083H487.75V597.083ZM456.417 654.75C453.471 654.75 451.083 652.362 451.083 649.417H446.083C446.083 655.124 450.71 659.75 456.417 659.75V654.75ZM500.917 649.417C500.917 652.362 498.529 654.75 495.583 654.75V659.75C501.29 659.75 505.917 655.124 505.917 649.417H500.917ZM505.917 602.417C505.917 596.71 501.29 592.083 495.583 592.083V597.083C498.529 597.083 500.917 599.471 500.917 602.417H505.917ZM451.083 602.417C451.083 599.471 453.471 597.083 456.417 597.083V592.083C450.71 592.083 446.083 596.71 446.083 602.417H451.083ZM462.482 631.601L470.316 639.434L473.851 635.899L466.018 628.066L462.482 631.601ZM473.851 639.434L489.518 623.768L485.982 620.232L470.316 635.899L473.851 639.434ZM472.083 589.25H479.917V584.25H472.083V589.25ZM479.917 599.917H472.083V604.917H479.917V599.917ZM472.083 599.917C469.138 599.917 466.75 597.529 466.75 594.583H461.75C461.75 600.29 466.376 604.917 472.083 604.917V599.917ZM485.25 594.583C485.25 597.529 482.862 599.917 479.917 599.917V604.917C485.624 604.917 490.25 600.29 490.25 594.583H485.25ZM479.917 589.25C482.862 589.25 485.25 591.638 485.25 594.583H490.25C490.25 588.876 485.624 584.25 479.917 584.25V589.25ZM472.083 584.25C466.376 584.25 461.75 588.876 461.75 594.583H466.75C466.75 591.638 469.138 589.25 472.083 589.25V584.25Z" fill="currentColor" />
          <path d="M996.018 588.066C995.042 587.089 993.459 587.089 992.482 588.066C991.506 589.042 991.506 590.625 992.482 591.601L996.018 588.066ZM1002.08 597.667L1000.32 599.434C1001.29 600.411 1002.87 600.411 1003.85 599.434L1002.08 597.667ZM1019.52 583.768C1020.49 582.791 1020.49 581.209 1019.52 580.232C1018.54 579.256 1016.96 579.256 1015.98 580.232L1019.52 583.768ZM1030.92 562.417V609.417H1035.92V562.417H1030.92ZM1025.58 614.75H986.417V619.75H1025.58V614.75ZM981.083 609.417V562.417H976.083V609.417H981.083ZM986.417 557.083H994.25V552.083H986.417V557.083ZM1017.75 557.083H1025.58V552.083H1017.75V557.083ZM986.417 614.75C983.471 614.75 981.083 612.362 981.083 609.417H976.083C976.083 615.124 980.71 619.75 986.417 619.75V614.75ZM1030.92 609.417C1030.92 612.362 1028.53 614.75 1025.58 614.75V619.75C1031.29 619.75 1035.92 615.124 1035.92 609.417H1030.92ZM1035.92 562.417C1035.92 556.71 1031.29 552.083 1025.58 552.083V557.083C1028.53 557.083 1030.92 559.471 1030.92 562.417H1035.92ZM981.083 562.417C981.083 559.471 983.471 557.083 986.417 557.083V552.083C980.71 552.083 976.083 556.71 976.083 562.417H981.083ZM992.482 591.601L1000.32 599.434L1003.85 595.899L996.018 588.066L992.482 591.601ZM1003.85 599.434L1019.52 583.768L1015.98 580.232L1000.32 595.899L1003.85 599.434ZM1002.08 549.25H1009.92V544.25H1002.08V549.25ZM1009.92 559.917H1002.08V564.917H1009.92V559.917ZM1002.08 559.917C999.138 559.917 996.75 557.529 996.75 554.583H991.75C991.75 560.29 996.376 564.917 1002.08 564.917V559.917ZM1015.25 554.583C1015.25 557.529 1012.86 559.917 1009.92 559.917V564.917C1015.62 564.917 1020.25 560.29 1020.25 554.583H1015.25ZM1009.92 549.25C1012.86 549.25 1015.25 551.638 1015.25 554.583H1020.25C1020.25 548.876 1015.62 544.25 1009.92 544.25V549.25ZM1002.08 544.25C996.376 544.25 991.75 548.876 991.75 554.583H996.75C996.75 551.638 999.138 549.25 1002.08 549.25V544.25Z" fill="currentColor" />
          <path d="M212.75 909C212.75 927.087 198.087 941.75 180 941.75V946.75C200.849 946.75 217.75 929.849 217.75 909H212.75ZM180 941.75C161.913 941.75 147.25 927.087 147.25 909H142.25C142.25 929.849 159.151 946.75 180 946.75V941.75ZM147.25 909C147.25 890.913 161.913 876.25 180 876.25V871.25C159.151 871.25 142.25 888.151 142.25 909H147.25ZM180 876.25C198.087 876.25 212.75 890.913 212.75 909H217.75C217.75 888.151 200.849 871.25 180 871.25V876.25ZM193.167 909C193.167 916.272 187.272 922.167 180 922.167V927.167C190.033 927.167 198.167 919.033 198.167 909H193.167ZM180 922.167C172.728 922.167 166.833 916.272 166.833 909H161.833C161.833 919.033 169.967 927.167 180 927.167V922.167ZM166.833 909C166.833 901.728 172.728 895.833 180 895.833V890.833C169.967 890.833 161.833 898.967 161.833 909H166.833ZM180 895.833C187.272 895.833 193.167 901.728 193.167 909H198.167C198.167 898.967 190.033 890.833 180 890.833V895.833ZM203.158 882.307L189.31 896.154L192.846 899.69L206.693 885.842L203.158 882.307ZM189.31 921.846L203.158 935.693L206.693 932.158L192.846 918.31L189.31 921.846ZM170.69 896.154L156.842 882.307L153.307 885.842L167.154 899.69L170.69 896.154ZM167.154 918.31L153.307 932.158L156.842 935.693L170.69 921.846L167.154 918.31Z" fill="currentColor" />
          <path d="M762.75 929C762.75 947.087 748.087 961.75 730 961.75V966.75C750.849 966.75 767.75 949.849 767.75 929H762.75ZM730 961.75C711.913 961.75 697.25 947.087 697.25 929H692.25C692.25 949.849 709.151 966.75 730 966.75V961.75ZM697.25 929C697.25 910.913 711.913 896.25 730 896.25V891.25C709.151 891.25 692.25 908.151 692.25 929H697.25ZM730 896.25C748.087 896.25 762.75 910.913 762.75 929H767.75C767.75 908.151 750.849 891.25 730 891.25V896.25ZM743.167 929C743.167 936.272 737.272 942.167 730 942.167V947.167C740.033 947.167 748.167 939.033 748.167 929H743.167ZM730 942.167C722.728 942.167 716.833 936.272 716.833 929H711.833C711.833 939.033 719.967 947.167 730 947.167V942.167ZM716.833 929C716.833 921.728 722.728 915.833 730 915.833V910.833C719.967 910.833 711.833 918.967 711.833 929H716.833ZM730 915.833C737.272 915.833 743.167 921.728 743.167 929H748.167C748.167 918.967 740.033 910.833 730 910.833V915.833ZM753.158 902.307L739.31 916.154L742.846 919.69L756.693 905.842L753.158 902.307ZM739.31 941.846L753.158 955.693L756.693 952.158L742.846 938.31L739.31 941.846ZM720.69 916.154L706.842 902.307L703.307 905.842L717.154 919.69L720.69 916.154ZM717.154 938.31L703.307 952.158L706.842 955.693L720.69 941.846L717.154 938.31Z" fill="currentColor" />
          <path d="M467.167 850.5H447.583C443.257 850.5 439.75 854.007 439.75 858.333V893.583C439.75 897.91 443.257 901.417 447.583 901.417H502.417C506.743 901.417 510.25 897.91 510.25 893.583V858.333C510.25 854.007 506.743 850.5 502.417 850.5H482.833M467.167 850.5V846.583C467.167 842.257 470.674 838.75 475 838.75C479.326 838.75 482.833 842.257 482.833 846.583V850.5M467.167 850.5C467.167 854.826 470.674 858.333 475 858.333C479.326 858.333 482.833 854.826 482.833 850.5M463.25 881.833C467.576 881.833 471.083 878.326 471.083 874C471.083 869.674 467.576 866.167 463.25 866.167C458.924 866.167 455.417 869.674 455.417 874C455.417 878.326 458.924 881.833 463.25 881.833ZM463.25 881.833C468.366 881.833 472.718 885.103 474.331 889.667M463.25 881.833C458.134 881.833 453.781 885.103 452.168 889.667M486.75 870.083H498.5M486.75 885.75H494.583" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M1027.17 830.5H1007.58C1003.26 830.5 999.75 834.007 999.75 838.333V873.583C999.75 877.91 1003.26 881.417 1007.58 881.417H1062.42C1066.74 881.417 1070.25 877.91 1070.25 873.583V838.333C1070.25 834.007 1066.74 830.5 1062.42 830.5H1042.83M1027.17 830.5V826.583C1027.17 822.257 1030.67 818.75 1035 818.75C1039.33 818.75 1042.83 822.257 1042.83 826.583V830.5M1027.17 830.5C1027.17 834.826 1030.67 838.333 1035 838.333C1039.33 838.333 1042.83 834.826 1042.83 830.5M1023.25 861.833C1027.58 861.833 1031.08 858.326 1031.08 854C1031.08 849.674 1027.58 846.167 1023.25 846.167C1018.92 846.167 1015.42 849.674 1015.42 854C1015.42 858.326 1018.92 861.833 1023.25 861.833ZM1023.25 861.833C1028.37 861.833 1032.72 865.103 1034.33 869.667M1023.25 861.833C1018.13 861.833 1013.78 865.103 1012.17 869.667M1046.75 850.083H1058.5M1046.75 865.75H1054.58" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      </svg>

      <svg
        width={400}
        height={400}
        viewBox="0 0 1200 1025"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute left-full hidden md:block transform translate-y-[350px] -translate-x-full"
      >
        <g>
          <path d="M160.583 193.333H180.167V185.5C180.167 179.011 174.906 173.75 168.417 173.75C164.674 173.75 161.34 175.5 159.188 178.226M160.583 193.333H121.417M160.583 193.333V185.5C160.583 182.93 160.088 180.475 159.188 178.226M121.417 193.333H101.833V185.5C101.833 179.011 107.094 173.75 113.583 173.75C117.326 173.75 120.66 175.5 122.812 178.226M121.417 193.333V185.5C121.417 182.93 121.912 180.475 122.812 178.226M122.812 178.226C125.7 171.012 132.755 165.917 141 165.917C149.245 165.917 156.3 171.012 159.188 178.226M152.75 142.417C152.75 148.906 147.489 154.167 141 154.167C134.511 154.167 129.25 148.906 129.25 142.417C129.25 135.927 134.511 130.667 141 130.667C147.489 130.667 152.75 135.927 152.75 142.417ZM176.25 154.167C176.25 158.493 172.743 162 168.417 162C164.09 162 160.583 158.493 160.583 154.167C160.583 149.84 164.09 146.333 168.417 146.333C172.743 146.333 176.25 149.84 176.25 154.167ZM121.417 154.167C121.417 158.493 117.91 162 113.583 162C109.257 162 105.75 158.493 105.75 154.167C105.75 149.84 109.257 146.333 113.583 146.333C117.91 146.333 121.417 149.84 121.417 154.167Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M840.583 173.333H860.167V165.5C860.167 159.011 854.906 153.75 848.417 153.75C844.674 153.75 841.34 155.5 839.188 158.226M840.583 173.333H801.417M840.583 173.333V165.5C840.583 162.93 840.088 160.475 839.188 158.226M801.417 173.333H781.833V165.5C781.833 159.011 787.094 153.75 793.583 153.75C797.326 153.75 800.66 155.5 802.812 158.226M801.417 173.333V165.5C801.417 162.93 801.912 160.475 802.812 158.226M802.812 158.226C805.7 151.012 812.755 145.917 821 145.917C829.245 145.917 836.3 151.012 839.188 158.226M832.75 122.417C832.75 128.906 827.489 134.167 821 134.167C814.511 134.167 809.25 128.906 809.25 122.417C809.25 115.927 814.511 110.667 821 110.667C827.489 110.667 832.75 115.927 832.75 122.417ZM856.25 134.167C856.25 138.493 852.743 142 848.417 142C844.09 142 840.583 138.493 840.583 134.167C840.583 129.84 844.09 126.333 848.417 126.333C852.743 126.333 856.25 129.84 856.25 134.167ZM801.417 134.167C801.417 138.493 797.91 142 793.583 142C789.257 142 785.75 138.493 785.75 134.167C785.75 129.84 789.257 126.333 793.583 126.333C797.91 126.333 801.417 129.84 801.417 134.167Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M85.75 427.333L116.655 447.937C119.286 449.691 122.714 449.691 125.345 447.937L156.25 427.333M93.5833 470.417H148.417C152.743 470.417 156.25 466.91 156.25 462.583V423.417C156.25 419.09 152.743 415.583 148.417 415.583H93.5833C89.2571 415.583 85.75 419.09 85.75 423.417V462.583C85.75 466.91 89.2571 470.417 93.5833 470.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M697.75 397.333L728.655 417.937C731.286 419.691 734.714 419.691 737.345 417.937L768.25 397.333M705.583 440.417H760.417C764.743 440.417 768.25 436.91 768.25 432.583V393.417C768.25 389.09 764.743 385.583 760.417 385.583H705.583C701.257 385.583 697.75 389.09 697.75 393.417V432.583C697.75 436.91 701.257 440.417 705.583 440.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M97.75 668.167H168.25M113.417 687.75H117.333M133 687.75H136.917M109.5 703.417H156.5C162.989 703.417 168.25 698.156 168.25 691.667V660.333C168.25 653.844 162.989 648.583 156.5 648.583H109.5C103.011 648.583 97.75 653.844 97.75 660.333V691.667C97.75 698.156 103.011 703.417 109.5 703.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M719.75 667.167H790.25M735.417 686.75H739.333M755 686.75H758.917M731.5 702.417H778.5C784.989 702.417 790.25 697.156 790.25 690.667V659.333C790.25 652.844 784.989 647.583 778.5 647.583H731.5C725.011 647.583 719.75 652.844 719.75 659.333V690.667C719.75 697.156 725.011 702.417 731.5 702.417Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M531.417 151.25V88.5833C531.417 84.2571 527.91 80.75 523.583 80.75H484.417C480.09 80.75 476.583 84.2571 476.583 88.5833V151.25M531.417 151.25L539.25 151.25M531.417 151.25H511.833M476.583 151.25L468.75 151.25M476.583 151.25H496.167M492.25 96.4166H496.167M492.25 112.083H496.167M511.833 96.4166H515.75M511.833 112.083H515.75M496.167 151.25V131.667C496.167 129.503 497.92 127.75 500.083 127.75H507.917C510.08 127.75 511.833 129.503 511.833 131.667V151.25M496.167 151.25H511.833" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M1101.42 111.25V48.5833C1101.42 44.2571 1097.91 40.75 1093.58 40.75H1054.42C1050.09 40.75 1046.58 44.2571 1046.58 48.5833V111.25M1101.42 111.25L1109.25 111.25M1101.42 111.25H1081.83M1046.58 111.25L1038.75 111.25M1046.58 111.25H1066.17M1062.25 56.4166H1066.17M1062.25 72.0832H1066.17M1081.83 56.4166H1085.75M1081.83 72.0832H1085.75M1066.17 111.25V91.6666C1066.17 89.5035 1067.92 87.7499 1070.08 87.7499H1077.92C1080.08 87.7499 1081.83 89.5035 1081.83 91.6666V111.25M1066.17 111.25H1081.83" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M411.965 366.083H419.583C423.91 366.083 427.417 369.59 427.417 373.917V377.833C427.417 382.16 430.924 385.667 435.25 385.667C439.576 385.667 443.083 389.174 443.083 393.5V405.035M431.333 338.414V344.542C431.333 349.949 435.717 354.333 441.125 354.333H443.083C447.41 354.333 450.917 357.84 450.917 362.167C450.917 366.493 454.424 370 458.75 370C463.076 370 466.583 366.493 466.583 362.167C466.583 357.84 470.09 354.333 474.417 354.333L478.586 354.333M458.75 403.244V393.5C458.75 389.174 462.257 385.667 466.583 385.667H478.586M482.25 370C482.25 389.468 466.468 405.25 447 405.25C427.532 405.25 411.75 389.468 411.75 370C411.75 350.532 427.532 334.75 447 334.75C466.468 334.75 482.25 350.532 482.25 370Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M981.965 326.083H989.583C993.91 326.083 997.417 329.59 997.417 333.917V337.833C997.417 342.16 1000.92 345.667 1005.25 345.667C1009.58 345.667 1013.08 349.174 1013.08 353.5V365.035M1001.33 298.414V304.542C1001.33 309.949 1005.72 314.333 1011.13 314.333H1013.08C1017.41 314.333 1020.92 317.84 1020.92 322.167C1020.92 326.493 1024.42 330 1028.75 330C1033.08 330 1036.58 326.493 1036.58 322.167C1036.58 317.84 1040.09 314.333 1044.42 314.333L1048.59 314.333M1028.75 363.244V353.5C1028.75 349.174 1032.26 345.667 1036.58 345.667H1048.59M1052.25 330C1052.25 349.468 1036.47 365.25 1017 365.25C997.532 365.25 981.75 349.468 981.75 330C981.75 310.532 997.532 294.75 1017 294.75C1036.47 294.75 1052.25 310.532 1052.25 330Z" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M466.018 628.066C465.041 627.089 463.459 627.089 462.482 628.066C461.506 629.042 461.506 630.625 462.482 631.601L466.018 628.066ZM472.083 637.667L470.316 639.434C471.292 640.411 472.875 640.411 473.851 639.434L472.083 637.667ZM489.518 623.768C490.494 622.791 490.494 621.209 489.518 620.232C488.541 619.256 486.959 619.256 485.982 620.232L489.518 623.768ZM500.917 602.417V649.417H505.917V602.417H500.917ZM495.583 654.75H456.417V659.75H495.583V654.75ZM451.083 649.417V602.417H446.083V649.417H451.083ZM456.417 597.083H464.25V592.083H456.417V597.083ZM487.75 597.083H495.583V592.083H487.75V597.083ZM456.417 654.75C453.471 654.75 451.083 652.362 451.083 649.417H446.083C446.083 655.124 450.71 659.75 456.417 659.75V654.75ZM500.917 649.417C500.917 652.362 498.529 654.75 495.583 654.75V659.75C501.29 659.75 505.917 655.124 505.917 649.417H500.917ZM505.917 602.417C505.917 596.71 501.29 592.083 495.583 592.083V597.083C498.529 597.083 500.917 599.471 500.917 602.417H505.917ZM451.083 602.417C451.083 599.471 453.471 597.083 456.417 597.083V592.083C450.71 592.083 446.083 596.71 446.083 602.417H451.083ZM462.482 631.601L470.316 639.434L473.851 635.899L466.018 628.066L462.482 631.601ZM473.851 639.434L489.518 623.768L485.982 620.232L470.316 635.899L473.851 639.434ZM472.083 589.25H479.917V584.25H472.083V589.25ZM479.917 599.917H472.083V604.917H479.917V599.917ZM472.083 599.917C469.138 599.917 466.75 597.529 466.75 594.583H461.75C461.75 600.29 466.376 604.917 472.083 604.917V599.917ZM485.25 594.583C485.25 597.529 482.862 599.917 479.917 599.917V604.917C485.624 604.917 490.25 600.29 490.25 594.583H485.25ZM479.917 589.25C482.862 589.25 485.25 591.638 485.25 594.583H490.25C490.25 588.876 485.624 584.25 479.917 584.25V589.25ZM472.083 584.25C466.376 584.25 461.75 588.876 461.75 594.583H466.75C466.75 591.638 469.138 589.25 472.083 589.25V584.25Z" fill="currentColor" />
          <path d="M996.018 588.066C995.042 587.089 993.459 587.089 992.482 588.066C991.506 589.042 991.506 590.625 992.482 591.601L996.018 588.066ZM1002.08 597.667L1000.32 599.434C1001.29 600.411 1002.87 600.411 1003.85 599.434L1002.08 597.667ZM1019.52 583.768C1020.49 582.791 1020.49 581.209 1019.52 580.232C1018.54 579.256 1016.96 579.256 1015.98 580.232L1019.52 583.768ZM1030.92 562.417V609.417H1035.92V562.417H1030.92ZM1025.58 614.75H986.417V619.75H1025.58V614.75ZM981.083 609.417V562.417H976.083V609.417H981.083ZM986.417 557.083H994.25V552.083H986.417V557.083ZM1017.75 557.083H1025.58V552.083H1017.75V557.083ZM986.417 614.75C983.471 614.75 981.083 612.362 981.083 609.417H976.083C976.083 615.124 980.71 619.75 986.417 619.75V614.75ZM1030.92 609.417C1030.92 612.362 1028.53 614.75 1025.58 614.75V619.75C1031.29 619.75 1035.92 615.124 1035.92 609.417H1030.92ZM1035.92 562.417C1035.92 556.71 1031.29 552.083 1025.58 552.083V557.083C1028.53 557.083 1030.92 559.471 1030.92 562.417H1035.92ZM981.083 562.417C981.083 559.471 983.471 557.083 986.417 557.083V552.083C980.71 552.083 976.083 556.71 976.083 562.417H981.083ZM992.482 591.601L1000.32 599.434L1003.85 595.899L996.018 588.066L992.482 591.601ZM1003.85 599.434L1019.52 583.768L1015.98 580.232L1000.32 595.899L1003.85 599.434ZM1002.08 549.25H1009.92V544.25H1002.08V549.25ZM1009.92 559.917H1002.08V564.917H1009.92V559.917ZM1002.08 559.917C999.138 559.917 996.75 557.529 996.75 554.583H991.75C991.75 560.29 996.376 564.917 1002.08 564.917V559.917ZM1015.25 554.583C1015.25 557.529 1012.86 559.917 1009.92 559.917V564.917C1015.62 564.917 1020.25 560.29 1020.25 554.583H1015.25ZM1009.92 549.25C1012.86 549.25 1015.25 551.638 1015.25 554.583H1020.25C1020.25 548.876 1015.62 544.25 1009.92 544.25V549.25ZM1002.08 544.25C996.376 544.25 991.75 548.876 991.75 554.583H996.75C996.75 551.638 999.138 549.25 1002.08 549.25V544.25Z" fill="currentColor" />
          <path d="M212.75 909C212.75 927.087 198.087 941.75 180 941.75V946.75C200.849 946.75 217.75 929.849 217.75 909H212.75ZM180 941.75C161.913 941.75 147.25 927.087 147.25 909H142.25C142.25 929.849 159.151 946.75 180 946.75V941.75ZM147.25 909C147.25 890.913 161.913 876.25 180 876.25V871.25C159.151 871.25 142.25 888.151 142.25 909H147.25ZM180 876.25C198.087 876.25 212.75 890.913 212.75 909H217.75C217.75 888.151 200.849 871.25 180 871.25V876.25ZM193.167 909C193.167 916.272 187.272 922.167 180 922.167V927.167C190.033 927.167 198.167 919.033 198.167 909H193.167ZM180 922.167C172.728 922.167 166.833 916.272 166.833 909H161.833C161.833 919.033 169.967 927.167 180 927.167V922.167ZM166.833 909C166.833 901.728 172.728 895.833 180 895.833V890.833C169.967 890.833 161.833 898.967 161.833 909H166.833ZM180 895.833C187.272 895.833 193.167 901.728 193.167 909H198.167C198.167 898.967 190.033 890.833 180 890.833V895.833ZM203.158 882.307L189.31 896.154L192.846 899.69L206.693 885.842L203.158 882.307ZM189.31 921.846L203.158 935.693L206.693 932.158L192.846 918.31L189.31 921.846ZM170.69 896.154L156.842 882.307L153.307 885.842L167.154 899.69L170.69 896.154ZM167.154 918.31L153.307 932.158L156.842 935.693L170.69 921.846L167.154 918.31Z" fill="currentColor" />
          <path d="M762.75 929C762.75 947.087 748.087 961.75 730 961.75V966.75C750.849 966.75 767.75 949.849 767.75 929H762.75ZM730 961.75C711.913 961.75 697.25 947.087 697.25 929H692.25C692.25 949.849 709.151 966.75 730 966.75V961.75ZM697.25 929C697.25 910.913 711.913 896.25 730 896.25V891.25C709.151 891.25 692.25 908.151 692.25 929H697.25ZM730 896.25C748.087 896.25 762.75 910.913 762.75 929H767.75C767.75 908.151 750.849 891.25 730 891.25V896.25ZM743.167 929C743.167 936.272 737.272 942.167 730 942.167V947.167C740.033 947.167 748.167 939.033 748.167 929H743.167ZM730 942.167C722.728 942.167 716.833 936.272 716.833 929H711.833C711.833 939.033 719.967 947.167 730 947.167V942.167ZM716.833 929C716.833 921.728 722.728 915.833 730 915.833V910.833C719.967 910.833 711.833 918.967 711.833 929H716.833ZM730 915.833C737.272 915.833 743.167 921.728 743.167 929H748.167C748.167 918.967 740.033 910.833 730 910.833V915.833ZM753.158 902.307L739.31 916.154L742.846 919.69L756.693 905.842L753.158 902.307ZM739.31 941.846L753.158 955.693L756.693 952.158L742.846 938.31L739.31 941.846ZM720.69 916.154L706.842 902.307L703.307 905.842L717.154 919.69L720.69 916.154ZM717.154 938.31L703.307 952.158L706.842 955.693L720.69 941.846L717.154 938.31Z" fill="currentColor" />
          <path d="M467.167 850.5H447.583C443.257 850.5 439.75 854.007 439.75 858.333V893.583C439.75 897.91 443.257 901.417 447.583 901.417H502.417C506.743 901.417 510.25 897.91 510.25 893.583V858.333C510.25 854.007 506.743 850.5 502.417 850.5H482.833M467.167 850.5V846.583C467.167 842.257 470.674 838.75 475 838.75C479.326 838.75 482.833 842.257 482.833 846.583V850.5M467.167 850.5C467.167 854.826 470.674 858.333 475 858.333C479.326 858.333 482.833 854.826 482.833 850.5M463.25 881.833C467.576 881.833 471.083 878.326 471.083 874C471.083 869.674 467.576 866.167 463.25 866.167C458.924 866.167 455.417 869.674 455.417 874C455.417 878.326 458.924 881.833 463.25 881.833ZM463.25 881.833C468.366 881.833 472.718 885.103 474.331 889.667M463.25 881.833C458.134 881.833 453.781 885.103 452.168 889.667M486.75 870.083H498.5M486.75 885.75H494.583" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M1027.17 830.5H1007.58C1003.26 830.5 999.75 834.007 999.75 838.333V873.583C999.75 877.91 1003.26 881.417 1007.58 881.417H1062.42C1066.74 881.417 1070.25 877.91 1070.25 873.583V838.333C1070.25 834.007 1066.74 830.5 1062.42 830.5H1042.83M1027.17 830.5V826.583C1027.17 822.257 1030.67 818.75 1035 818.75C1039.33 818.75 1042.83 822.257 1042.83 826.583V830.5M1027.17 830.5C1027.17 834.826 1030.67 838.333 1035 838.333C1039.33 838.333 1042.83 834.826 1042.83 830.5M1023.25 861.833C1027.58 861.833 1031.08 858.326 1031.08 854C1031.08 849.674 1027.58 846.167 1023.25 846.167C1018.92 846.167 1015.42 849.674 1015.42 854C1015.42 858.326 1018.92 861.833 1023.25 861.833ZM1023.25 861.833C1028.37 861.833 1032.72 865.103 1034.33 869.667M1023.25 861.833C1018.13 861.833 1013.78 865.103 1012.17 869.667M1046.75 850.083H1058.5M1046.75 865.75H1054.58" stroke="currentColor"  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      </svg>
    </div>
  </div>
)
