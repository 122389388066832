import React from 'react'
import Layout from './mdxLayout'
import { IconBackground } from './IconBackground'
import { classNames } from '../ultilities/classNames'

interface PageLayoutProps {
  title: string
  children: any
  fullWidth?: boolean
  hideLinks?: boolean
}

export const CompanyPageLayout: React.FC<PageLayoutProps> = ({ title, children, fullWidth = false, hideLinks = false }) => {
  const classes = classNames(
    'p-4 sm:p-6 lg:p-8 mx-auto prose',
    fullWidth ? 'max-w-full' : 'max-w-5xl'
  )

  return (
    <Layout hideLinks={hideLinks}>
      <div className="bg-grape py-12 relative overflow-hidden">
        <div className="text-white text-opacity-10">
          <IconBackground />
        </div>
        <h1 className="text-center text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
          {title}
        </h1>
      </div>
      <div className="bg-white py-12 shadow-overlay">
        <div className={classes}>
          {children}
        </div>
      </div>
    </Layout>
  )
}
