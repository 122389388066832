import React from 'react'
import { Footer } from './footer'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from './seo'
import { Helmet } from 'react-helmet'
import { TopNav } from './TopNav'

interface LayoutProps {
  children: any
  hideLinks?: boolean
}

export const Layout: React.FC<LayoutProps> = ({ children, hideLinks = false }) => {
  const { allMdx } = useStaticQuery(query)

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#491849"/>
      </Helmet>

      <div className="bg-grape pt-[88px]">
        <SEO/>
        <TopNav hideLinks={hideLinks} />
        {children}
        <Footer hideLinks={hideLinks} />
      </div>
    </>
  )
}

export const withLayout: React.FC = (props) => <Layout {...props}/>

export default Layout

const query = graphql`
query {
  allMdx {
    edges {
      node {
        frontmatter {
          title
        }
      }
    }
  }
}
`
